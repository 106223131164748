import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby';

export const ExtLinkBtn = styled.a`
  display: block;
  padding: 10px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  background-color: ${({bgColor}) => bgColor || "salmon"};
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`

export const LinkBtn = styled(Link)`
  display: block;
  padding: 10px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  background-color: ${({bgColor}) => bgColor || "salmon"};
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`

export const Btn = styled.button.attrs(({type}) => ({
  type: type || "button"
}))`
  display: block;
  padding: 10px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  background-color: ${({bgColor}) => bgColor || "salmon"};
  /* outline: none; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
`

const IFrameCont = styled.div`
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
`

const IFrame = styled.iframe`
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`

export const RespIframe = () => {
  return (
  <IFrameCont>
    <IFrame></IFrame>
  </IFrameCont>)
}
const theme = {
  primaryDark1: '#0D0C1D',
  primaryDark2: 'dark-grey',
  primaryDark3: 'grey',
  primaryLight1: 'white',
  primaryLight2: '#fafafa',
  primaryLight3: 'light-grey',
  primaryHover: 'salmon',
  babyBlue: '#C1D6F3',
  salmon: 'salmon',
  mobileMenu: '799px',
  headerHeightBig: '80px'
}

export default theme;
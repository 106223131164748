import React, { createContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { H1, H2, H3, P, Hr, Ul, Ol } from "./layout/Styles";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import styled from 'styled-components';
import WhatsAppWidget from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";

export const RootContext = createContext();

const WaContainer = styled.div`
  width: 84px;
  height: 84px;
  position: fixed;
  bottom: 0px;
  right: -30px;
`


const RootElement = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          navData {
            label
            link
          }
        }
      }
      file(name: { eq: "logo" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 200)
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={theme}>
      <MDXProvider
        components={{
          h1: H1,
          h2: H2,
          h3: H3,
          p: P,
          hr: Hr,
          ul: Ul,
          ol: Ol,
        }}
      >
        <RootContext.Provider
          value={{
            title: data.site.siteMetadata.title,
            navItems: data.site.siteMetadata.navData,
            logo: data.file.childImageSharp.gatsbyImageData,
            // themeColors,
          }}
        >
          {children}
          <WaContainer>
            <WhatsAppWidget
              textReplyTime="Typically replies within an hour"
              phoneNumber="+270410085010"
              companyName="Eyechart Online"
              message="Hi there. How can we help you?"
            />
          </WaContainer>
        </RootContext.Provider>
      </MDXProvider>
    </ThemeProvider>
  );
};

export default RootElement;

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-how-mdx": () => import("./../../../src/pages/how.mdx" /* webpackChunkName: "component---src-pages-how-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-what-js": () => import("./../../../src/pages/what.js" /* webpackChunkName: "component---src-pages-what-js" */)
}


import SEO from "../../../../src/components/seo";
import { graphql } from "gatsby";
import mainNavVid from "../../../../src/videos/main-nav.mp4";
import mouseVid from "../../../../src/videos/mouse2.mp4";
import calibVid from "../../../../src/videos/calibrate.mp4";
import { BlockEl, FullLengthFlexBox, Block280 } from "../../../../src/components/layout/Styles";
import Img from "gatsby-image";
import { GatsbyImage } from "gatsby-plugin-image";
import { ExtLinkBtn, LinkBtn } from "../../../../src/components/layout/ui.js";
import * as React from 'react';
export default {
  SEO,
  graphql,
  mainNavVid,
  mouseVid,
  calibVid,
  BlockEl,
  FullLengthFlexBox,
  Block280,
  Img,
  GatsbyImage,
  ExtLinkBtn,
  LinkBtn,
  React
};
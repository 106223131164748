import styled from 'styled-components'

export const H1 = styled.h1`
  text-align: center;
  color: salmon;
  width: 100%;
  margin: 30px auto;
`

export const P = styled.p`
  line-height: 2em;
  width: 100%;
  margin: 30px auto;
`

export const Hr = styled.hr`
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000;
  opacity: 0.2;
  margin: 30px auto;
`

export const Ul = styled.ul`
  width: 90%;
  margin: 30px auto 30px 10%;
  line-height: 2em;
`

export const Ol = styled.ol`
  width: 90%;
  margin: 30px auto 30px 10%;
  line-height: 2em;
`

export const H2 = styled.h2`
  display: block;
  width: 100%;
  margin: 30px auto;
`

export const H3 = styled.h2`
  display: block;
  width: 100%;
  margin: 30px auto;
  font-size: 1.4em;
`
export const BlockEl = styled.div`
  display: block;
  max-width: 100%;
  margin: 30px auto;
  border: 1px solid black;
  border-radius: 15px;
  overflow: hidden;
`
export const Block280 = styled.div`
  display: block;
  width: 280px;
`
export const Block640 = styled.div`
  display: block;
  width: 280px;
  @media(min-width: 700px) {
    width: 640px
  }
`
export const FullLengthFlexBox = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: 30px auto;
`